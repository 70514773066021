export function linkResolver(value: string | any) {
  if (value.type === "buscar-exame") {
    return `/buscar-exame/${value.uid}`;
  }

  if (value.type === "exames") {
    return `/${value.uid}`;
  }

  return "/";
}
