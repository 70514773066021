import React from "react";
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews";
import { linkResolver } from "../../linkResolver";

function Preview() {
  return (
    <div>
      <h1>Loading preview…</h1>
    </div>
  );
}

export default withPrismicPreviewResolver(Preview, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO ?? "",
    linkResolver
  }
]);
